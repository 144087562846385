<template>
  <b-overlay
    variant="white"
    :show="isLoading"
    spinner-variant="primary"
    blur="0"
    opacity=".5"
    rounded="sm"
  >
    <b-row>
      <b-col>
        <b-card title="Generate NPP">
          <div v-if="userLoggedIn.role_name == 'Admin Nasional'">
            <b-table
              :items="items" 
              :fields="fieldsAdmin" 
              responsive
              striped
              show-empty
              :busy="busy1"
            >
              <!-- Checkbox di Header -->
              <template #head(select)>
                <b-form-checkbox v-model="selectAll" @change="toggleSelectAll"></b-form-checkbox>
              </template>
              
              <!-- Checkbox di Setiap Baris -->
              <template #cell(select)="data">
                <b-form-checkbox v-model="data.item.selected" @change="getSelectedItems"></b-form-checkbox>
              </template>

              <template #cell(no)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(employee)="data">
                {{ data.item.employee }} / {{ data.item.name }}
              </template>
              <template #cell(daily_money)="data">
                <span class="text-capitalize d-block" style="text-align: end;">
                  {{ data.item.daily_money | currency }}
                </span>
              </template>
              <template #cell(total_accomodation)="data">
                <span class="text-capitalize d-block" style="text-align: end;">
                  {{ data.item.total_accomodation | currency }}
                </span>
              </template>
              <template #cell(total_real_expense)="data">
                <span class="text-capitalize d-block" style="text-align: end;">
                  {{ data.item.total_real_expense | currency }}
                </span>
              </template>
              <template #cell(total_transportation)="data">
                <span class="text-capitalize d-block" style="text-align: end;">
                  {{ data.item.total_transportation | currency }}
                </span>
              </template>
              <template #cell(total_anggaran)="data">
                <span class="text-capitalize d-block" style="text-align: end;">
                  {{ data.item.total_anggaran | currency }}
                </span>
              </template>
            </b-table>

            <b-card-footer align="right">
              <b-button variant="secondary" @click="goBack()">Batal</b-button>
              <b-button
                v-if="items.length && selectedItem.length"
                variant="primary"
                class="ml-1"
                @click="save()"
                :disabled="isLoading"
              >
                <div v-if="isLoading">
                  Simpan <feather-icon icon="LoaderIcon" />
                </div>
                <div v-else>Simpan</div>
              </b-button>
            </b-card-footer>
          </div>
          <div v-else>
            <b-table 
              :items="items" 
              :fields="fieldsRegional" 
              responsive
              striped
              show-empty
              :busy="busy2"
            >
              <template #cell(no)="data">
                {{ data.index + 1 }}
              </template>
              <template #cell(employee)="data">
                {{ data.item.employee }} / {{ data.item.name }}
              </template>
              <template #cell(daily_money)="data">
                <span class="text-capitalize d-block" style="text-align: end;">
                  {{ data.item.daily_money | currency }}
                </span>
              </template>
              <template #cell(total_accomodation)="data">
                <span class="text-capitalize d-block" style="text-align: end;">
                  {{ data.item.total_accomodation | currency }}
                </span>
              </template>
              <template #cell(total_real_expense)="data">
                <span class="text-capitalize d-block" style="text-align: end;">
                  {{ data.item.total_real_expense | currency }}
                </span>
              </template>
              <template #cell(total_transportation)="data">
                <span class="text-capitalize d-block" style="text-align: end;">
                  {{ data.item.total_transportation | currency }}
                </span>
              </template>
              <template #cell(total_anggaran)="data">
                <span class="text-capitalize d-block" style="text-align: end;">
                  {{ data.item.total_anggaran | currency }}
                </span>
              </template>
            </b-table>

            <b-card-footer align="right">
              <b-button variant="secondary" @click="goBack()">Batal</b-button>
              <b-button
                v-if="items.length"
                variant="primary"
                class="ml-1"
                @click="save()"
                :disabled="isLoading"
              >
                <div v-if="isLoading">
                  Simpan <feather-icon icon="LoaderIcon" />
                </div>
                <div v-else>Simpan</div>
              </b-button>
            </b-card-footer>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { BRow, BCol, BCard, BTable, BCardFooter, BButton, BOverlay, BFormCheckbox } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

import { getUserData } from '@/auth/utils'


export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BCardFooter,
    BButton,
    BOverlay,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userLoggedIn: getUserData(),
      isLoading: false,
      selectAll: false,
      busy1: false,
      busy2: false,
      items: [],
      fieldsAdmin: [
        { key: "select", label: "" },
        'no',
        { key: 'employee', label: 'NIPPOS / Nama' },
        { key: 'sppd_number', label: 'Nomor SPPD' },
        { key: 'name_subdirektorat', label: 'Sub Direktorat' },
        { key: 'name_regional', label: 'Regional' },
        { key: 'daily_money', label: 'Uang Harian' },
        { key: 'total_real_expense', label: 'Biaya Pengeluaran Rill' },
        { key: 'total_accomodation', label: 'Biaya Akomodasi' },
        { key: 'total_transportation', label: 'Biaya Transportasi' },
        { key: 'total_anggaran', label: 'Total Biaya' },
      ],
      fieldsRegional: [
        'no',
        { key: 'employee', label: 'NIPPOS / Nama' },
        { key: 'sppd_number', label: 'Nomor SPPD' },
        { key: 'name_subdirektorat', label: 'Sub Direktorat' },
        { key: 'name_regional', label: 'Regional' },
        { key: 'daily_money', label: 'Uang Harian' },
        { key: 'total_real_expense', label: 'Biaya Pengeluaran Rill' },
        { key: 'total_accomodation', label: 'Biaya Akomodasi' },
        { key: 'total_transportation', label: 'Biaya Transportasi' },
        { key: 'total_anggaran', label: 'Total Biaya' },
      ],
      model: {
        sppds: []
      },
      selectedItem: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.busy1 = true;
      this.busy2 = true;
      axios.get('/npps/preview').then(res => {
        // this.items = res.data.data
        if(this.userLoggedIn.role_name == "Admin Nasional"){
          this.items = res.data.data.map(item => ({
            ...item,
            selected: false // Tambahkan properti 'selected' ke setiap item
          }));
        } else {
          this.items = res.data.data
        }
      })
      .catch(error => {
        this.loading = false
        console.error(error)

        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      })
      .finally(() => {
        this.busy1 = false;
        this.busy2 = false;
      });
    },
    goBack() {
      this.$router.back()
    },
    toggleSelectAll() {
      this.items.forEach(item => {
        item.selected = this.selectAll;
      });

      const selectedIds = this.items
        .filter(item => item.selected); 
      this.selectedItem = selectedIds
      // console.log("ID yang dipilih:", selectedIds);
    },
    getSelectedItems() {
      const selectedIds = this.items
        .filter(item => item.selected); // Ambil hanya 'sppd_id'

      // console.log("ID yang dipilih:", selectedIds);
      this.selectedItem = selectedIds;
    },
    save() {
      this.isLoading = true
      
      if(this.userLoggedIn.role_name == "Admin Nasional"){
        const selectedIds = this.selectedItem
          .filter(item => item.selected) // Hanya item yang dipilih
          .map(item => item.sppd_id); // Ambil hanya 'sppd_id'

        this.model.sppds = selectedIds
        // console.log(JSON.stringify(this.model.sppds));
      }

      axios.post('/npps', this.model).then((response) => {

        const responseMessage = response.message; // Mengambil data dari respons
        // console.log('Response Data:', response); // Log untuk melihat data

        this.isLoading = false
        this.$router.push({
          name: 'manajemen-npp',
          query: {
            event: 'success',
            title: 'Berhasil',
            text: responseMessage ?? 'NPP berhasil digenerate',
          },
        })
      })
      .catch(error => {
        this.isLoading = false
        console.error(error)
        var message = "";
        if(typeof error.response.data.message === 'object'){
          message = error.response.data.message.errors[0].msg
        } else {
          message = error.response.data.message
        }
        this.makeToast('danger', "Terjadi Kesalahan", message)
      })
    },
    makeToast(variant = null, title = 'Title here...', text = 'Text here...') {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      })
    },
  },
}
</script>

<style></style>
